<template>
  <div class="app-container">
    <el-card shadow="never">
      <el-tabs v-model="activeName">
        <el-tab-pane label="PC端装修" name="1">
          <el-card shadow="never">
            <div class="tag-wrap" @click="toPcPage()">
              <div class="tag">
                <span class="img blue-color">
                  <i class="el-icon-brush"></i>
                </span>
                <span class="text"> Diy页面 </span>
              </div>
            </div>
          </el-card>
        </el-tab-pane>
        <el-tab-pane label="手机端装修" name="2">
          <el-card shadow="never">
            <div class="tag-wrap" @click="toMobilePage()">
              <div class="tag">
                <span class="img blue-color">
                  <i class="el-icon-brush"></i>
                </span>
                <span class="text"> Diy页面 </span>
              </div>
            </div>
          </el-card>
        </el-tab-pane>
      </el-tabs>
    </el-card>
  </div>
</template>
<script>
import { mapState } from "vuex";

import { getToken } from "@/utils/auth";

export default {
  data() {
    return {
      activeName: "1",
    };
  },
  computed: {
    ...mapState({}),
  },

  mounted() { },
  created() { },
  methods: {
    /**
     * 跳转到Pc页面
     *  */
    toPcPage() {
      // 获取当前路径
      const domain = window.location.origin;

      // 拼接新路径
      const newPath = `${domain}/api/page/template?type=1&token=` + getToken();

      window.open(newPath, "_blank");
    },

    /**
     * 跳转到手机装修页面
     */
    toMobilePage() {
      // 获取当前路径
      const domain = window.location.origin;

      // 拼接新路径
      const newPath = `${domain}/api/page/template?type=2&token=` + getToken();

      window.open(newPath, "_blank");
    },
  },
};
</script>
<style scoped>
.tag-wrap {
  margin: 10px 0 20px;
}

.tag-wrap>.tag {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.tag-wrap>.tag {
  height: 70px;
  width: 250px;
  margin-right: 10px;
  padding: 20px;
  background: #f7f8fa;
  border-radius: 6px;
  background-color: #f7f8fa;
  cursor: pointer;
  margin-top: 5px;
}

.tag-wrap>.tag>.img {
  background-color: #3d7fff;
  background-color: var(--color-brand1-6, #3d7fff);
}

.tag-wrap>.tag>.img {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  height: 30px;
  width: 30px;
  margin-right: 12px;
  border-radius: 11.67px;
  color: #fff;
  color: var(--color-white, #fff);
}

.tag-wrap>.tag>.text {
  line-height: 30px;
  font-size: 14px;
  color: #111;
  color: var(--color-text1-4, #111);
}
</style>
